import React, { useContext, useEffect, useRef, useState } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import EmptyLayout from "../layouts/EmptyLayout";
import MainLayout from "../layouts/MainLayout";
import LoginIndex from "../Auth/LoginIndex";
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import ForgotPasswordIndex from "../Auth/ForgotPasswordIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import ResetPassword from "../Auth/ResetPassword";
import SettingsIndex from "../Settings/SettingsIndex";
import PrivateKey from "../Auth/PrivateKey";
import CreateCollection from "../Collection/CreateCollection";
import EmailVerificationIndex from "../Auth/EmailVerificationIndex";
import CreateItem from "../CollectionItem/CreateItem";
import TwoStepVerification from "../Auth/TwoStepVerification";
import SettingsProfile from "../Settings/SettingsProfile";
import SettingsSecurity from "../Settings/SettingsSecurity";
import SettingsWallet from "../Settings/SettingsWallet";
import Logout from "../Auth/Logout";
import OnBoardIndex from "../Auth/OnBoarding/OnBoardIndex";
import MyCollection from "../Collection/MyCollection";
import EditCollection from "../Collection/EditCollection";
import Collection from "../CollectionItem/Collection";
import ViewPrivateKey from "../Auth/OnBoarding/ViewPrivateKey";
import SettingWithdraw from "../Settings/SettingWithdraw";
import SingleNftImage from "../CollectionItem/SingleNftImage";
import TrendingArtwork from "../LandingPage/TrendingArtwork";
import TopCollection from "../LandingPage/TopCollection";
import ProfileIndex from "../Profile/ProfileIndex";
import ListingItemIndex from "../CollectionItem/ListingItemIndex";
import CollectionActivityCard from "../CollectionItem/CollectionActivityCard";
import Activity from "../SingleLandingPage/ActivityIndex";
import ActivityIndex from "../SingleLandingPage/ActivityIndex";
import OfferIndex from "../SingleLandingPage/OfferIndex";
import StaticPageIndex from "../StaticPage/StaticPageIndex";
import SuccessMint from "../Mint/SuccessMint";
import FailedMint from "../Mint/FailedMint";
import PageNotFound from "../404/PageNotFound";
import OtherUserProfileIndex from "../Profile/OtherUserProfileIndex";
import SocialMediaImportIndex from "../SocialMediaImport/SocialMediaImportIndex";
import SocialMediaSelectedPostIndex from "../SocialMediaImport/SocialMediaSelectedPostIndex";
import CreateNewItemIndex from "../SocialMediaImport/CreateNewItemIndex";
import BuyIndex from "../Buy/BuyIndex";
import AuthLogin from "../Auth/AuthLogin";

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");
  let authentication =
    userId && accessToken && userLoginStatus == "true" ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");

  const [loading, setLoading] = useState(true);

  const [configLoading, setConfigLoading] = useState(true);

  const [authentication, setAuthntication] = useState(
    userId && accessToken && userLoginStatus == "true" ? true : false
  );

  history.listen((location, action) => {
    userId = localStorage.getItem("userId");
    accessToken = localStorage.getItem("accessToken");
    userLoginStatus = localStorage.getItem("userLoginStatus");
    setLoading(true);
    setAuthntication(
      userId && accessToken && userLoginStatus == "true" ? true : false
    );
    document.body.scrollTop = 0;
  });

  return (
    <>
      {/* <Helmet>
				<title>{configuration.get("configData.site_name")}</title>
				<link
					rel="icon"
					type="image/png"
					href={configuration.get("configData.site_icon")}
				/>
				<script src={configuration.get("configData.head_scripts")}></script>
			</Helmet> */}
      <Routes>
        <Route
          path={"/"}
          element={
            <AppRoute component={LandingPageIndex} layout={MainLayout} />
          }
        />
        <Route
          path={"/login"}
          element={
            <AppRoute component={LoginIndex} layout={AuthenticationLayout} />
          }
        />
        <Route
          path={"/register"}
          element={
            <AppRoute component={RegisterIndex} layout={AuthenticationLayout} />
          }
        />
        <Route
          path={"/forgot-password"}
          element={
            <AppRoute
              component={ForgotPasswordIndex}
              layout={AuthenticationLayout}
            />
          }
        />
        <Route
          path={"/reset-password"}
          element={
            <AppRoute component={ResetPassword} layout={AuthenticationLayout} />
          }
        />
        <Route
          path={"/private-key"}
          element={
            <AppRoute component={PrivateKey} layout={AuthenticationLayout} />
          }
        />
        <Route
          path={"/verification"}
          element={
            <AppRoute component={TwoStepVerification} layout={EmptyLayout} />
          }
        />
        <Route
          path={"/register-verify"}
          element={
            <AppRoute component={EmailVerificationIndex} layout={EmptyLayout} />
          }
        />
        <Route
          path={"/onboarding"}
          element={
            <PrivateRoute component={OnBoardIndex} layout={MainLayout} />
          }
        />
        <Route
          path={"/view-private-key"}
          element={
            <PrivateRoute
              component={ViewPrivateKey}
              layout={AuthenticationLayout}
            />
          }
        />
        <Route
          path={"/profile-setting"}
          element={<AppRoute component={SettingsProfile} layout={MainLayout} />}
        />
        <Route
          path={"/authentication"}
          element={
            <AppRoute component={SettingsSecurity} layout={MainLayout} />
          }
        />
        <Route
          path={"/wallet"}
          element={<AppRoute component={SettingsWallet} layout={MainLayout} />}
        />
        <Route
          path={"/withdraw"}
          element={<AppRoute component={SettingWithdraw} layout={MainLayout} />}
        />
        <Route
          path={"/create-collection"}
          element={
            <AppRoute component={CreateCollection} layout={MainLayout} />
          }
        />
        <Route
          path={"/my-collection"}
          element={
            <PrivateRoute component={MyCollection} layout={MainLayout} />
          }
        />
        <Route
          path={"/my-collection/:nft_collection_unique_id"}
          element={
            <PrivateRoute component={EditCollection} layout={MainLayout} />
          }
        />
        <Route
          path={"/collection/:nft_collection_unique_id"}
          element={
            <AppRoute component={Collection} layout={MainLayout} />
          }
        />
        <Route
          path={"/create-item"}
          element={<AppRoute component={CreateItem} layout={MainLayout} />}
        />
        <Route
          path={`/assets/:blockchain_type/:nft_collection_unique_id/:contract_address/:token_id`}
          element={<AppRoute component={SingleNftImage} layout={MainLayout} />}
        />
        <Route
          path={"/sell-item/:nft_collection_unique_id/:contract_address/:token_id"}
          element={
            <AppRoute component={ListingItemIndex} layout={MainLayout} />
          }
        />
        <Route
          path={"/item-list"}
          element={<AppRoute component={Collection} layout={MainLayout} />}
        />
        <Route
          path={"/trending-artwork"}
          element={<AppRoute component={TrendingArtwork} layout={MainLayout} />}
        />
        <Route
          path={"/top-collection"}
          element={<AppRoute component={TopCollection} layout={MainLayout} />}
        />
        <Route
          path={"/profile"}
          element={<PrivateRoute component={ProfileIndex} layout={MainLayout} />}
        />
        <Route
          path={"/profile-created"}
          element={<PrivateRoute component={ProfileIndex} layout={MainLayout} />}
        />
        <Route
          path={"/profile-favorited"}
          element={<PrivateRoute component={ProfileIndex} layout={MainLayout} />}
        />
        <Route
          path={"/account/:user_unique_id"}
          element={<AppRoute component={OtherUserProfileIndex} layout={MainLayout} />}
        />
        <Route
          path={"/account/:user_unique_id/created"}
          element={<AppRoute component={OtherUserProfileIndex} layout={MainLayout} />}
        />
        <Route
          path={"/account/:user_unique_id/favorited"}
          element={<AppRoute component={OtherUserProfileIndex} layout={MainLayout} />}
        />
        <Route
          path={"/activity/:nft_collection_unique_id/:nft_collection_item_token_id"}
          element={<AppRoute component={ActivityIndex} layout={MainLayout} />}
        />
        <Route
          path={"/offers/:nft_collection_unique_id/:nft_collection_item_token_id"}
          element={<AppRoute component={OfferIndex} layout={MainLayout} />}
        />
        <Route
          path={"/page/:static_page_unique_id"}
          element={<AppRoute component={StaticPageIndex} layout={MainLayout} />}
        />
        <Route
          path={"/success-mint"}
          element={<AppRoute component={SuccessMint} layout={MainLayout} />}
        />
        <Route
          path={"/failed-mint"}
          element={<AppRoute component={FailedMint} layout={MainLayout} />}
        />
        <Route
          path={"/social-media-import"}
          element={<AppRoute component={SocialMediaImportIndex} layout={MainLayout} />}
        />
        <Route
          path={"/social-media-select-post"}
          element={<AppRoute component={SocialMediaSelectedPostIndex} layout={MainLayout} />}
        />
        <Route
          path={"/create-new-item/:post_unique_id"}
          element={<AppRoute component={CreateNewItemIndex} layout={MainLayout} />}
        />
        <Route
          path={"/post"}
          element={
            <PrivateRoute
              // authentication={authentication}
              component={LandingPageIndex}
              layout={EmptyLayout}
            />
          }
        />
        <Route
          path={"/logout"}
          element={<AppRoute component={Logout} layout={MainLayout} />}
        />
        <Route
          path={"/buy-tokens"}
          element={<AppRoute component={BuyIndex} layout={MainLayout} />}
        />
        <Route
          path={"/auth-login"}
          element={<AppRoute component={AuthLogin} layout={MainLayout} />}
        />

        <Route
          path={"*"}
          element={<AppRoute component={PageNotFound} layout={EmptyLayout} />}
        />
      </Routes>
    </>
  );
};

export default App;
