import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  userLoginStart,
} from "../store/actions/UserAction";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

const exceptionPaths = ["/view-private-key", "/logout"];

const AuthLogin = (props) => {
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = useAuth0();

  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    console.log("Loggeed in", isLoading, isAuthenticated);
    if (!isLoading) {
      if (isAuthenticated && user) {
        props.dispatch(
          userLoginStart({
            name: user.name,
            first_name: user.given_name ? user.given_name : "",
            last_name: user.family_name ? user.family_name : "",
            email: user.email ? user.email : "",
            picture: user.picture,
          })
        );
      } else if (
        !isLoading && (!isAuthenticated || user === undefined)
      ) {
        navigate("/logout");
      }
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (!skipRender && isAuthenticated) {
      if (
        !props.loginInputData.loading &&
        Object.keys(props.loginInputData.data).length > 0
      ) {
        if (
          props.loginInputData.data.user.onboarding_step == 2 &&
          props.loginInputData.data.private_passphrase
        ) {
          navigate("/view-private-key");
        } else if (props.loginInputData.data.user.onboarding_step < 6) {
          navigate("/onboarding");
        } else {
          navigate("/");
        }
      }
    }
    setSkipRender(false);
  }, [props.loginInputData]);

  return (
    <div>

    </div>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  loginInputData: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AuthLogin);
