import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Table,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./CreateItem.css";
import { useDropzone } from "react-dropzone";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import AddPropertiesModal from "./AddPropertiesModal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { apiConstants } from "../Constant/constants";
import configuration from "react-global-configuration";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { authContext } from "../authprovider/AuthProvider";
import {
  fetchNftCollectionsListStart,
  nftItemsSaveStart,
  clearNftCollectionList,
} from "../store/actions/NftAction";
import { Formik, Form as FORM, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { CHAIN_INFO, SupportedChainId } from "../authprovider/chains";
import NftToken from "../../abis/NftToken.json";
import { connect } from "react-redux";
import DynamicNFTBuilder from "./DynamicNFT/DynamicNFTBuilder";

//Declare IPFS
const auth =
  "Basic " +
  Buffer.from(
    apiConstants.ipfs_project_id + ":" + apiConstants.ipfs_project_secret
  ).toString("base64");
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient.create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

// Declare IPFS json
const ipfsJson = require("nano-ipfs-store").at("https://ipfs.infura.io:5001");

const CreateItem = (props) => {
  // const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [skipRender, setSkipRender] = useState(true);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      let reader = new FileReader();
      let file = acceptedFiles[0];
      reader.onloadend = () => {
        setImage({
          ...image,
          file: file,
          preview_image: reader.result,
        });
      };
      if (file) {
        setCollectionItemData({
          ...collectionItemData,
          picture: file,
        });
        reader.readAsDataURL(file);
      }
    },
  });

  const nftBuilder = useRef(null);

  const [nftType, setNftType] = useState(1);

  const chainName = configuration.get("configData.native_currency_name")
    ? configuration.get("configData.native_currency_name")
    : "collection";

  const { auth } = useContext(authContext);

  const navigate = useNavigate();

  // const selectedCollection = searchParams.get("nft_collection_unique_id");

  const netID = configuration.get("configData.network_id")
    ? configuration.get("configData.network_id")
    : "97";

  const [collectionSelect, setCollectionSelect] = useState([]);

  const [collectionContractAddress, setCollectionContractAddress] =
    useState("");

  const [propertiesModal, setPropertiesModal] = useState({
    status: false,
    data: [],
  });

  const [collectionItemData, setCollectionItemData] = useState({
    name: "",
    description: "",
    properties: [],
    external_link: "",
    nft_collection_id: "",
    is_property_save: false,
    item_type: "1",
    is_minted: 0,
    royalty_percentage: "",
    royalty_wallet_address: "",
    royalty_type: 1,
    collection_type: 1,
    deployed_wallet_address: "",
  });

  const [image, setImage] = useState({
    file: "",
    preview_image: "",
  });

  const [propertiesSwapper, setPropertiesSwapper] = useState([]);

  const [nftContractData, setNftContractData] = useState("");

  const [img, setImg] = useState("");

  const [mintButtonContent, setMintButtonContent] = useState("");

  const [initialLoading, setInitialLoading] = useState(false);

  const [termsAgreed, setTermsAgreed] = useState(false);

  useEffect(() => {
    props.dispatch(clearNftCollectionList());
    setInitialLoading(true);
    // if (!auth.loading && auth.accounts != "" && auth.authStatus) {
    props.dispatch(fetchNftCollectionsListStart());
    // }
  }, []);

  // useEffect(() => {
  //   if (
  //     !auth.loading &&
  //     auth.accounts != "" &&
  //     auth.authStatus &&
  //     collectionContractAddress
  //   ) {
  //     connectBlockchain();
  //   }
  // }, [auth.authStatus, collectionContractAddress]);

  useEffect(() => {
    if (searchParams.get("nft_collection_unique_id") && initialLoading) {
      if (!props.collectionList.loading) {
        let royaltyType = collectionItemData.royalty_type;
        let collectionType = collectionItemData.collection_type;
        let deployedWalletAddress = collectionItemData.deployed_wallet_address;
        const selectedCollectionId = props.collectionList.data.collections.find(
          (data) =>
            data.nft_collection_id ===
            Number(searchParams.get("nft_collection_unique_id"))
        );
        if (selectedCollectionId) {
          setCollectionSelect([selectedCollectionId]);
          if (selectedCollectionId && selectedCollectionId.contract_address) {
            setCollectionContractAddress(selectedCollectionId.contract_address);
            royaltyType = selectedCollectionId.royalty_type;
            collectionType = selectedCollectionId.collection_type;
            deployedWalletAddress =
              selectedCollectionId.deployed_wallet_address;
          }

          setCollectionItemData({
            ...collectionItemData,
            nft_collection_id: Number(
              searchParams.get("nft_collection_unique_id")
            ),
            royalty_type: royaltyType,
            collection_type: collectionType,
            deployed_wallet_address: deployedWalletAddress,
          });
        }
      }
    }
  }, [props.collectionList.data]);

  const createNftItemschema = Yup.object().shape({
    picture: Yup.mixed(),
    name: Yup.string().required("Collection name is required"),
    external_link: Yup.string().required("Link is required"),
    royalty_wallet_address: Yup.string().when("royalty_percentage", {
      is: (royalty_percentage) => Number(royalty_percentage) > 0,
      then: Yup.string().required("Royalty Wallet address is required"),
      otherwise: Yup.string(),
    }),
  });

  const handleNetworkSelect = (value) => {
    setCollectionSelect([value]);
    setCollectionItemData({
      ...collectionItemData,
      nft_collection_id: value.nft_collection_id ? value.nft_collection_id : "",
      royalty_type: value.royalty_type ? value.royalty_type : 1,
      collection_type: value.collection_type ? value.collection_type : 1,
      deployed_wallet_address: value.deployed_wallet_address
        ? value.deployed_wallet_address
        : "",
    });
    setCollectionContractAddress(value.contract_address);
    // connectBlockchain();
  };

  const handlePropertiesModalOpen = () => {
    setPropertiesSwapper(collectionItemData.properties);
    if (!collectionItemData.is_property_save) {
      handleNewProperty();
    }
    setPropertiesModal({
      ...propertiesModal,
      status: true,
    });
  };

  const handlePropertiesModalClose = () => {
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: collectionItemData.properties.filter(
    //     (collection) => collection.name != "" && collection.value != ""
    //   ),
    // });

    setPropertiesSwapper([]);

    setPropertiesModal({
      ...propertiesModal,
      status: false,
    });
  };

  const handleAddImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setImage({
        ...image,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      setCollectionItemData({
        ...collectionItemData,
        picture: file,
      });
      reader.readAsDataURL(file);
    }
  };

  // const handleAddImageRemove = () => {
  //   setImage({
  //     ...image,
  //     file: "",
  //     preview_image: "",
  //   });
  // };

  const handleSubmit = async (values) => {
    let nftHtmlCode = "";
    let nftScreenShot = "";
    if (nftType !== 1) {
      nftHtmlCode = nftBuilder.current.generateCode();
      nftScreenShot = await nftBuilder.current.generateScreenShot();
    }

    const newData = {
      ...values,
      properties: JSON.stringify(values.properties),
      file_type: nftType,
      picture: nftType === 1 ? image.file : nftScreenShot,
      animation_url: nftHtmlCode,
      nft_collection_id:
        collectionSelect.length > 0
          ? collectionSelect[0].nft_collection_id
          : "",
      blockchain_type:
        collectionSelect.length > 0 ? collectionSelect[0].blockchain_type : "",
      deployed_wallet_address:
        collectionSelect.length > 0
          ? collectionSelect[0].deployed_wallet_address
          : "",
      item_type: collectionItemData.item_type,
      royalty_percentage:
        collectionItemData.royalty_percentage != ""
          ? collectionItemData.royalty_percentage
          : "0",
    };
    if (collectionItemData.item_type == "1") {
      mintYourNFT(newData);
    } else {
      props.dispatch(nftItemsSaveStart(newData));
    }
  };

  const handleNewProperty = () => {
    let newPropety = {
      name: "",
      value: "",
      nft_value_id: "",
    };
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: [...collectionItemData.properties, newPropety],
    // });

    setPropertiesSwapper([...propertiesSwapper, newPropety]);
  };

  const handlePropertyNameChange = (index, value) => {
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: collectionItemData.properties.map(
    //     (collection, collectionIndex) => {
    //       if (index === collectionIndex) {
    //         return {
    //           ...collection,
    //           name: value,
    //         };
    //       }
    //       return collection;
    //     }
    //   ),
    // });
    setPropertiesSwapper([
      ...propertiesSwapper.map((collection, collectionIndex) => {
        if (index === collectionIndex) {
          return {
            ...collection,
            name: value,
          };
        }
        return collection;
      }),
    ]);
  };

  const handlePropertyValueChange = (index, value) => {
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: collectionItemData.properties.map(
    //     (collection, collectionIndex) => {
    //       if (index === collectionIndex) {
    //         return {
    //           ...collection,
    //           value: value,
    //         };
    //       }
    //       return collection;
    //     }
    //   ),
    // });
    setPropertiesSwapper([
      ...propertiesSwapper.map((collection, collectionIndex) => {
        if (index === collectionIndex) {
          return {
            ...collection,
            value: value,
          };
        }
        return collection;
      }),
    ]);
  };

  const handlePropertyDelete = (removeCollection) => {
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: collectionItemData.properties.filter(
    //     (collection) => collection != removeCollection
    //   ),
    // });
    setPropertiesSwapper(
      propertiesSwapper.filter((collection) => collection != removeCollection)
    );
  };

  const handlePropertySave = () => {
    const saveProperties = propertiesSwapper.filter(
      (collection) =>
        collection.name.trim() != "" && collection.value.trim() != ""
    );

    setCollectionItemData({
      ...collectionItemData,
      properties: saveProperties,
      is_property_save: saveProperties.length > 0,
    });
    handlePropertiesModalClose();
  };

  const handleSocialLinkChanges = (event) => {
    setCollectionItemData({
      ...collectionItemData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.itemSave.loading &&
      Object.keys(props.itemSave.data).length > 0
    ) {
      const delayDebounceFn = setTimeout(() => {
        navigate(
          `/assets/${props.itemSave.data.nft_collection.blockchain_type}/${props.itemSave.data.nft_collection.nft_collection_unique_id}/${props.itemSave.data.nft_collection.contract_address}/${props.itemSave.data.nft_collection_item.token_id}`
        );
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
    setSkipRender(false);
  }, [props.itemSave.data]);

  // Blockchain code minting.

  // Connect blockchain.
  // const connectBlockchain = async () => {
  //   // NFT contract.
  //   const web3 = window.web3;
  //   let nftContractData;
  //   const NFTData = NftToken.networks[netID];
  //   if (NFTData) {
  //     nftContractData = await new web3.eth.Contract(
  //       NftToken.abi,
  //       collectionContractAddress
  //     );
  //     setNftContractData(nftContractData);
  //   } else {
  //     window.alert("NFT contract not deployed to detected network.");
  //   }
  // };

  const convertDataURIToBinaryFF = (dataURI) => {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var raw = window.atob(dataURI.substring(base64Index));
    return Uint8Array.from(
      Array.prototype.map.call(raw, function (x) {
        return x.charCodeAt(0);
      })
    );
  };

  const mintYourNFT = async (newData) => {
    let transaction_hash = "";
    const web3 = window.web3;
    let nftContractData;
    if (newData.collection_type == 1) {
      if (!auth.authStatus) {
        // props.dispatch(createNotification(notificationMessage));
        getErrorNotificationMessage("Please connect metamask.");
        return false;
      }

      // if (
      //   CHAIN_INFO[auth.chainId].nativeCurrency.symbol !=
      //   newData.blockchain_type
      // ) {
      //   getErrorNotificationMessage(
      //     `Collection is not deployed in the detected network.hello Please switch to ${newData.blockchain_type}`
      //   );
      //   // props.dispatch(createNotification(notificationMessage));
      //   return false;
      // }

      // if (auth.accounts != newData.deployed_wallet_address) {
      //   getErrorNotificationMessage(
      //     `Collection is not deployed in the detected wallet address. Please switch to ${String(
      //       newData.deployed_wallet_address
      //     ).substring(0, 5)}
      //     ...
      //     ${String(newData.deployed_wallet_address).substring(
      //       newData.deployed_wallet_address.length - 4
      //     )}`
      //   );
      //   // props.dispatch(createNotification(notificationMessage));
      //   return false;
      // }

      const NFTData = NftToken.networks[auth.chainId];
      // if (NFTData) {
        nftContractData = await new web3.eth.Contract(
          NftToken.abi,
          collectionContractAddress
        );
        setNftContractData(nftContractData);
      // } else {
      //   window.alert("NFT contract not deployed to detected network.");
      // }
    }
    setMintButtonContent("Initiated...");

    if (nftContractData != null || newData.collection_type == 2) {
      // fetch data from api.
      try {
        setMintButtonContent((prevState) => "Connecting to Blockchain");

        let imageData = await convertDataURIToBinaryFF(
          newData.file_type == 1 ? image.preview_image : newData.picture
        );
        imageData = Buffer(imageData);
        const cid = await ipfs.add(imageData);
        //adding file to the IPFS
        // ipfs.add(imageData, async (error, result) => {
          console.log("Ipfs result", cid);
          if (!cid || !cid.path) {
            console.error("Ipfs add failed");
            setMintButtonContent("");
            return;
          }
          setImg(cid.path);
          let itemFile =
            "https://nftmeta.infura-ipfs.io/ipfs/" + cid.path;
          if (newData.file_type == 1) {
            commonJsonGenerator(
              collectionItemData,
              itemFile,
              newData,
              nftContractData,
              ""
            );
          } else {
            let htmlData = Buffer(newData.animation_url);
            ipfs.add(htmlData, async (error, result) => {
              if (error) {
                console.error(error);
                return;
              }
              let nftHTMLIpfs =
                "https://nftmeta.infura-ipfs.io/ipfs/" + cid.path;
              commonJsonGenerator(
                collectionItemData,
                itemFile,
                newData,
                nftContractData,
                nftHTMLIpfs
              );
            });
          }
        // });
      } catch (error) {
        setMintButtonContent("");
        console.log("Error", error);
        const notificationMessage =
          "Something went wrong. Please refresh the page and try again.";
        console.log("Send notification.");
        // Send fail notification.
      }
    } else {
      setMintButtonContent("");
      // Send fail notification.
      getErrorNotificationMessage(
        "Something went wrong. Please refresh the page and try again."
      );
      // props.dispatch(createNotification(notificationMessage));
    }
  };

  const commonJsonGenerator = async (
    collectionItemData,
    itemFile,
    newData,
    nftContractData,
    animationHash
  ) => {
    console.log("ItemFile", itemFile);
    let allWords = "";
    collectionItemData.properties.map((property) => {
      allWords = allWords + " " + property.value;
    });
    console.log("Allwords", allWords);

    const json = generateJson({
      name: collectionItemData.name,
      description: collectionItemData.description,
      imageHash: itemFile,
      animationHash: animationHash,
      properties: collectionItemData.properties,
      allWords: allWords,
    }).then(async (val) => {
      try {
        console.log("cid start");
        val = Buffer(val);
        const cidArr = await ipfs.add(val);
        if (!cidArr || !cidArr.path) {
          console.error("Ipfs add failed");
          setMintButtonContent("");
          return;
        }
        
        const cid = cidArr.path;
        // const tokenURIHash = await ipfsJson.cat(cid);
        console.log("cid", cid);
        console.log("minter address", auth.accounts);
        console.log("all words", allWords);
        if (collectionItemData.collection_type == 1) {
          if (collectionItemData.royalty_type == 1) {
            console.log("mint Common Royality");
            nftContractData.methods
              .mint(
                auth.accounts,
                "https://nftmeta.infura-ipfs.io/ipfs/" + cid,
                allWords
              )
              .send({ gasPrice: '10000000000', from: auth.accounts })
              .on("error", (error) => {
                let notificationMessage;
                if (error.message == undefined) {
                  notificationMessage =
                    "Same Wallet can't have more than 2 NFT! Use different wallet address";
                } else {
                  notificationMessage = error.message;
                }
                setMintButtonContent("");
                console.log("Send notification.");
                // Send fail notification.
              })
              .once("receipt", (receipt) => {
                // sendTransactionApi(
                //   receipt.transactionHash,
                // );
                console.log(
                  "Receipt",
                  receipt.events.Transfer.returnValues.tokenId
                );
                const updateData = {
                  ...newData,
                  transaction_hash: receipt.transactionHash,
                  token_id: receipt.events.Transfer.returnValues.tokenId,
                  token_uri: "https://nftmeta.infura-ipfs.io/ipfs/" + cid,
                  is_minted: 1,
                  wallet_address: auth.accounts,
                };
                props.dispatch(nftItemsSaveStart(updateData));
              });
          } else {
            console.log("mintIndividualRoyality");
            nftContractData.methods
              .mintIndividualRoyality(
                auth.accounts,
                "https://nftmeta.infura-ipfs.io/ipfs/" + cid,
                allWords,
                collectionItemData.royalty_wallet_address == ""
                  ? auth.accounts
                  : collectionItemData.royalty_wallet_address,
                collectionItemData.royalty_percentage == ""
                  ? "0"
                  : String(Number(collectionItemData.royalty_percentage) * 10)
              )
              .send({ gasPrice: '10000000000', from: auth.accounts })
              .on("error", (error) => {
                let notificationMessage;
                if (error.message == undefined) {
                  notificationMessage =
                    "Same Wallet can't have more than 2 NFT! Use different wallet address";
                } else {
                  notificationMessage = error.message;
                }
                setMintButtonContent("");
                console.log("Send notification.");
                // Send fail notification.
              })
              .once("receipt", (receipt) => {
                // sendTransactionApi(
                //   receipt.transactionHash,
                // );
                console.log(
                  "Receipt",
                  receipt.events.Transfer.returnValues.tokenId
                );
                const updateData = {
                  ...newData,
                  transaction_hash: receipt.transactionHash,
                  token_id: receipt.events.Transfer.returnValues.tokenId,
                  token_uri: "https://nftmeta.infura-ipfs.io/ipfs/" + cid,
                  is_minted: 1,
                  wallet_address: auth.accounts,
                };
                props.dispatch(nftItemsSaveStart(updateData));
              });
          }
        } else {
          const updateData = {
            ...newData,
            token_uri: "https://nftmeta.infura-ipfs.io/ipfs/" + cid,
            is_minted: 0,
            wallet_address: collectionItemData.deployed_wallet_address,
          };
          props.dispatch(nftItemsSaveStart(updateData));
        }
      } catch (error) {
        setMintButtonContent("");
        // Send fail notification.
        getErrorNotificationMessage(
          "Something went wrong. Please refresh the page and try again."
        );
        // props.dispatch(createNotification(notificationMessage));
        console.log(error);
      }
    });
  };

  const sendTransactionApi = (transactionHash) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        transaction_id: transactionHash,
        wallet_address: auth.accounts,
      }),
    };
    try {
      fetch(
        "https://api.nft-generator.online/api/user/nft_images_complete",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            const notificationMessage = "Minted Successfully";
            // window.location.reload();
          } else {
            const notificationMessage = "Saving data error..";
          }
        });
    } catch (error) {
      const notificationMessage =
        "Something went wrong. Please refresh the page and try again.";
    }
  };

  // Generate metadata json file.
  const generateJson = async (data) => {
    let attributes = [];

    data.properties.map((property) =>
      attributes.push({ trait_type: property.name, value: property.value })
    );
    console.log("Attributes", attributes);
    let metadata;
    if (data.animationHash) {
      metadata = JSON.stringify({
        description: data.description,
        external_url: data.external_link,
        image: data.imageHash,
        animation_url: data.animationHash,
        name: data.name,
        text: data.allWords,
        attributes,
      });
    } else {
      metadata = JSON.stringify({
        description: data.description,
        external_url: data.external_link,
        image: data.imageHash,
        name: data.name,
        text: data.allWords,
        attributes,
      });
    }
    console.log("Json", metadata);
    return metadata;
  };

  //prevent reload

  useEffect(() => {
    if (mintButtonContent != "") {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [mintButtonContent]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleTermsChange = () => {
    setTermsAgreed((prev) => !prev);
  };

  function isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  const handleRoyaltyChange = (event) => {
    setCollectionItemData({
      ...collectionItemData,
      royalty_percentage: isInt(Number(event.target.value))
        ? event.target.value
        : Number(event.target.value).toFixed(1),
    });
  };

  return (
    <>
      <div className="create-new-item-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="step-1">
                <div className="create-new-item-wrapper">
                  <div className="create-new-item-header">
                    <Image
                      onClick={() => navigate(-1)}
                      src={
                        window.location.origin +
                        "/images/landing-page/icons/back-arrow.svg"
                      }
                      className="back-arrow-icon"
                    />
                  </div>
                  <div
                    className={`${nftType === 3 ? "dynamic-nft-box" : "create-new-item-box"
                      }`}
                  >
                    {nftType === 1 ? (
                      <div className="create-new-item-left-sec">
                        <div className="create-new-item-sub-title">
                          <Image
                            src={
                              window.location.origin +
                              "/images/create-item/icons/insta.svg"
                            }
                            className="create-new-item-icon"
                          />
                          <span>NFT</span>
                        </div>
                        <div className="create-new-item-left-card">
                          <div className="create-new-item-left-img-sec">
                            <Image
                              src={
                                image.preview_image != ""
                                  ? image.preview_image
                                  : window.location.origin +
                                  "/images/create-item/nft.png"
                              }
                              className="create-new-item-left-img"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div
                      className={`${nftType === 3
                        ? "row justify-content-md-center"
                        : "create-new-item-right-sec"
                        }`}
                    >
                      <div
                        className={`${nftType === 3 ? "col-md-12 col-xl-8 col-lg-10" : ""
                          }`}
                      >
                        <div className="create-new-item-right-header-sec">
                          <span>1</span>
                          <h2>Create New NFT</h2>
                        </div>
                        {/* <div className="dynamic-nft-and-image-sec">
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="image"
                                control={<Radio checked={nftType === 1} />}
                                label="Social media post"
                                onClick={() => setNftType(1)}
                              />
                              <FormControlLabel
                                value="dynamic-nft"
                                control={<Radio checked={nftType === 3} />}
                                label="Dynamic NFT"
                                onClick={() => setNftType(3)}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div> */}
                        <div className="create-new-item-right-form-box">
                          <Formik
                            initialValues={{
                              ...collectionItemData,
                              picture: image.file,
                            }}
                            className="w-100"
                            validationSchema={createNftItemschema}
                            onSubmit={(values) => handleSubmit(values)}
                            enableReinitialize
                          >
                            {({
                              errors,
                              touched,
                              setFieldValue,
                              values,
                              isSubmitting,
                            }) => (
                              <FORM className="create-new-item-right-form">
                                {nftType === 1 ? (
                                  <Form.Group className="mb-3">
                                    <Form.Label>Image*</Form.Label>
                                    <Form.Text>
                                      File types supported: jpg, png, jpeg, bmp,
                                      gif. Max size:5 MB
                                    </Form.Text>
                                    <div className="create-item-logo-item">
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="create-item-logo-icon-card">
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/images/create-collection/icons/plus-icon.svg"
                                            }
                                            className="create-collection-logo-icon"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Form.Group>
                                ) : null}
                                {nftType === 3 ? (
                                  <Col md={12}>
                                    <div className="create-new-item-full-width-sec">
                                      <div className="mt-3 mb-3">
                                        <DynamicNFTBuilder ref={nftBuilder} />
                                      </div>
                                    </div>
                                  </Col>
                                ) : null}
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Name*</Form.Label>
                                  <Field
                                    type="text"
                                    name="name"
                                    placeholder="NFT name"
                                    className={`form-control ${touched.name && errors.name
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                    onChange={(event) =>
                                      handleSocialLinkChanges(event)
                                    }
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="name"
                                    className="invalid-feedback mt-3"
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Description</Form.Label>
                                  <Field
                                    as="textarea"
                                    rows={3}
                                    type="text"
                                    name="description"
                                    placeholder="Please explain what your NFT is all about."
                                    className={`form-control`}
                                    value={collectionItemData.description}
                                    onChange={(event) =>
                                      handleSocialLinkChanges(event)
                                    }
                                  />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Label>External Link*</Form.Label>
                                  <Form.Text>
                                    Marketplace will include a link to link to
                                    this URL on this nft's detail page, so that
                                    users can click to learn more about it. You
                                    are welcome to your own webpage with more
                                    details.
                                  </Form.Text>
                                  <Field
                                    type="text"
                                    name="external_link"
                                    placeholder="http://yoursite.io/item/123"
                                    className={`form-control ${touched.external_link &&
                                      errors.external_link
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                    onChange={(event) =>
                                      handleSocialLinkChanges(event)
                                    }
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="external_link"
                                    className="invalid-feedback mt-3"
                                  />
                                </Form.Group>
                                <Form.Label>Collection</Form.Label>
                                <Form.Group className="mb-3">
                                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    {/* {props.collectionList.loading ? null : (
                                    <CustomSelect
                                      value={collectionSelect}
                                      handleChange={handleNetworkSelect}
                                      items={
                                        props.collectionList.data.nft_collections
                                      }
                                      placeHolder={"Select Collection"}
                                      idBased
                                      nameField={"name"}
                                      valueField={"contract_address"}
                                      noDataMessage={"No Collection Found"}
                                    />
                                  )} */}
                                    {props.collectionList.loading ? null : (
                                      <Select
                                        value={collectionSelect}
                                        displayEmpty
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                        onChange={(e) =>
                                          handleNetworkSelect(e.target.value)
                                        }
                                        renderValue={(selected) => {
                                          if (selected.length === 0) {
                                            return <em>Select Collection</em>;
                                          }
                                          return selected.map(
                                            (selected) => selected.name
                                          );
                                        }}
                                      >
                                        {props.collectionList.data
                                          .collections &&
                                          props.collectionList.data.collections.map(
                                            (collection, i) => {
                                              return (
                                                <MenuItem
                                                  key={i}
                                                  value={collection}
                                                >
                                                  {collection.name}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                      </Select>
                                    )}
                                  </FormControl>
                                </Form.Group>
                                {collectionItemData.royalty_type == 2 && (
                                  <>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Royalties</Form.Label>
                                      <p>
                                        Collect a fee when a user re-sells an
                                        nft you originally created. This is
                                        deducted from the final sale price and
                                        paid monthly to a payout address of your
                                        choosing.
                                      </p>
                                      <Field
                                        type="number"
                                        name="royalty_percentage"
                                        placeholder="E.g 4.5"
                                        className={`form-control ${touched.royalty_percentage &&
                                          errors.royalty_percentage
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        min="0"
                                        step="any"
                                        value={
                                          collectionItemData.royalty_percentage
                                        }
                                        onChange={(event) =>
                                          handleRoyaltyChange(event)
                                        }
                                      />
                                    </Form.Group>
                                    {Number(
                                      collectionItemData.royalty_percentage
                                    ) > 0 && (
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Label>
                                            Royalty Wallet Address*
                                          </Form.Label>
                                          <p>
                                            Royalty percentage will be sent to the
                                            provided wallet address.
                                          </p>
                                          <Field
                                            type="text"
                                            name="royalty_wallet_address"
                                            placeholder="E.g 4.5"
                                            className={`form-control ${touched.royalty_wallet_address &&
                                              errors.royalty_wallet_address
                                              ? "is-invalid"
                                              : ""
                                              }`}
                                            min="0"
                                            step="any"
                                            value={
                                              collectionItemData.royalty_wallet_address
                                            }
                                            onChange={(event) =>
                                              handleSocialLinkChanges(event)
                                            }
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="royalty_wallet_address"
                                            className="invalid-feedback mt-3"
                                          />
                                        </Form.Group>
                                      )}
                                  </>
                                )}
                                <Form.Group>
                                  <div className="create-item-properties-card">
                                    <div className="create-item-properties-item">
                                      <Image
                                        src={
                                          window.location.origin +
                                          "/images/create-item/icons/properties-icon.svg"
                                        }
                                        className="create-item-logo-icon"
                                      />
                                      <div className="create-item-properties-info">
                                        <Form.Label>Properties</Form.Label>
                                        <p>
                                          textual traits that show up as
                                          rectangles
                                        </p>
                                      </div>
                                    </div>
                                    <div className="create-item-properties-icon-sec">
                                      <Button
                                        className="create-item-properties-btn"
                                        onClick={() =>
                                          handlePropertiesModalOpen()
                                        }
                                      >
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/images/create-collection/icons/plus-icon.svg"
                                          }
                                          className="create-item-properties-icon"
                                        />
                                      </Button>
                                    </div>
                                  </div>
                                  {collectionItemData.properties.length > 0 && (
                                    <div className="created-item-properties-box">
                                      {collectionItemData.properties.map(
                                        (collectionProperty, index) => (
                                          <>
                                            {collectionProperty.name != "" &&
                                              collectionProperty.value !=
                                              "" && (
                                                <div className="created-item-properties-card">
                                                  <p>
                                                    {collectionProperty.name}
                                                  </p>
                                                  <h5>
                                                    {collectionProperty.value}
                                                  </h5>
                                                </div>
                                              )}
                                          </>
                                        )
                                      )}
                                    </div>
                                  )}
                                </Form.Group>
                                <Form.Group>
                                  <div className="agree-terms-sec">
                                    <Form.Check
                                      inline
                                      name="group1"
                                      checked={termsAgreed}
                                      onChange={() => handleTermsChange()}
                                    />
                                    <p>
                                      I agree to the following{" "}
                                      <Link to="/page/terms">
                                        terms & conditions
                                      </Link>
                                    </p>
                                  </div>
                                </Form.Group>
                                <div className="create-item-btn-sec">
                                  <Button
                                    className="default-btn"
                                    type="submit"
                                    disabled={
                                      props.itemSave.buttonDisable ||
                                      mintButtonContent != "" ||
                                      !termsAgreed
                                    }
                                  >
                                    {mintButtonContent != ""
                                      ? "Your NFT is being minted..."
                                      : "Create NFT"}
                                  </Button>
                                </div>
                              </FORM>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <AddPropertiesModal
        show={propertiesModal.status}
        onHide={handlePropertiesModalClose}
        properties={propertiesSwapper}
        handleNewProperty={handleNewProperty}
        handlePropertyNameChange={handlePropertyNameChange}
        handlePropertyValueChange={handlePropertyValueChange}
        handlePropertyDelete={handlePropertyDelete}
        handlePropertySave={handlePropertySave}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  collectionList: state.nfts.collectionList,
  itemSave: state.nfts.itemSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateItem);
