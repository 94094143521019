import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <Auth0Provider
  //   domain="dev-2tfdd7dzj13ibfel.us.auth0.com"
  //   clientId="WKdcpT53E7op3qd6KQuyv52eXUM6a8cM"
  //   authorizationParams={{
  //     redirect_uri: window.location.origin + '/auth-login',
  //   }}
  // >
    <App />
  // </Auth0Provider>
);
