import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Form,
  Button,
  Accordion,
} from "react-bootstrap";
import "./SingleItem.css";
import { Link } from "react-router-dom";
import ActivityOffersIndex from "./ActivityOffersIndex";
import MoreFromCollection from "./MoreFromCollection";
import CancelListingModal from "./CancelListingModal";
import BuyNFTModal from "./BuyNFTModal";
import FavoritedByModal from "./FavoritedByModal";
import MakeofferModal from "./MakeofferModal";
import TransferNFTModal from "./TransferNFTModal";
import ReportCollectionModal from "./ReportCollectionModal";
import { authContext } from "../authprovider/AuthProvider";
import { useLocation, useParams, useNavigate } from "react-router";
import { useTheme } from "@mui/material/styles";
import configuration from "react-global-configuration";
import { connect } from "react-redux";
import {
  fetchSingleNftItemsStart,
  moreFromCollectionStart,
  nftItemsPaymentListStart,
  nftItemFavouriteUsersListStart,
  nftFavouritesSaveStart,
  loadMoreItemActivityStart,
  clearNftSingleSellData,
  clearCancelListingData,
  clearNftBuyData,
  buyNftItemStart,
  cancelListNftItemStart,
  clearTransferNftData,
  clearNftSingleImageData,
  bidOfferNftItemListStart,
  bidOfferNftItemSaveStart,
  acceptOfferNftItemStart,
  cancelBidOfferNftItemStart,
} from "../store/actions/NftAction";
import useValidation from "../customHooks/useValidation";
import NftToken from "../../abis/NftToken.json";
import LazyNft from "../../abis/LazyNFT.json";
import SellerContract from "../../abis/MarketPlaceContract.json";
import Web3 from "web3";
import MarketPlaceContract from "../../abis/MarketPlaceContract.json";
import moment from "moment";
import useUserAuthStatus from "../customHooks/useUserAuthStatus";
import {
  CHAIN_INFO,
  SupportedChainId,
  SupportedChainIdBySymbol,
} from "../authprovider/chains";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import PageNotFound from "../404/PageNotFound";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CopyToClipboard from "react-copy-to-clipboard";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import SingleNftImageLoader from "../loaders/SingleNftImageLoader";
import ActivityOfferLoader from "../loaders/ActivityOfferLoader";

const queryString = require("query-string");

const ethPrice = require("eth-price");

const SingleNftImage = (props) => {
  const location = useLocation();

  const [localSingleNftImage, setLocalSingleNftImage] = useState(null);
  const [cancelListing, setCancelListing] = useState(false);

  const closeCancelListingModal = () => {
    setCancelListing(false);
  };

  const [buyNFT, setBuyNFT] = useState(false);

  const closeBuyNFTModal = () => {
    setBuyNFT(false);
  };

  const [favoritedBy, setFavoritedBy] = useState(false);

  const closeFavoritedByModal = () => {
    setFavoritedBy(false);
  };

  const [transferNFT, setTransferNFT] = useState(false);

  const closeTransferNFTModal = () => {
    setTransferNFT(false);
  };

  const [reportCollection, setReportCollection] = useState(false);

  const closeReportCollectionModal = () => {
    setReportCollection(false);
  };

  const [profileOpen, setProfileOpen] = React.useState(false);
  const handleProfile = () => {
    setProfileOpen((prev) => !prev);
  };
  const handleProfileAway = () => {
    setProfileOpen(false);
  };
  const profile = {
    position: "absolute",
    width: 240,
    top: 18,
    right: 0,
    left: -255,
    zIndex: 9999,
    border: "1px solid transparent",
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  };

  // ======Old Code==================

  const { auth, loginConnectors, handleConnector, price } =
    useContext(authContext);

  const { nft_collection_unique_id, contract_address, token_id } = useParams();

  const theme = useTheme();

  const navigate = useNavigate();

  const nft_collection_item_unique_id =
    !props.singleNftImage.loading &&
      props.singleNftImage.data.nft_collection_item
      ? props.singleNftImage.data.nft_collection_item
        .nft_collection_item_unique_id
      : "";

  const [priceSelect, setPriceSelect] = useState([
    configuration.get("configData.native_currency_symbol"),
  ]);

  const [cancelListingModal, setCancelListingModal] = useState(false);

  const [favouriteStatus, setFavouriteStatus] = useState(false);

  const [favouritesCount, setFavouritesCount] = useState(0);

  const [usdPrice, setUsdPrice] = useState(0);

  const [nftContractData, setNftContractData] = useState("");

  const [sellerContract, setSellerContract] = useState("");

  const [buttonContent, setButtonContent] = useState("");

  const [cancelListingButtonContent, setCancelListingButtonContent] =
    useState("");

  const [transferModal, setTranseferModal] = useState({
    status: false,
  });

  const [activeId, setActiveId] = useState("4");

  const [priceSort, setPriceSort] = useState([]);

  const [signData, setSignData] = useState({
    signature: null,
    status: false,
    loading: false,
    loadingContent: null,
  });

  const [makeOfferButtonContent, setMakeOfferButtonContent] = useState("");

  const [cancelOfferBtnContent, setCancelOfferBtnContent] = useState("");

  const [acceptOfferBtnContent, setAcceptOfferBtnContent] = useState("");

  const [activeCancelListingAccordion, setActiveCancelListingAccordion] =
    useState("1");

  const [activeTransferAccordion, setActiveTransferAccordion] = useState("1");

  const [currentOwner, setCurrentOwner] = useState(null);

  const [paymentMode, setPaymentMode] = useState("CRYPTO");

  const [clamped, setClamped] = useState(true);

  const [showReadButton, setShowReadButton] = useState(true);

  const handleReadClick = () => setClamped(!clamped);

  const toggleActiveAccordion = (id) => {
    setActiveCancelListingAccordion(id);
  };

  const toggleActiveTransferAccordion = (id) => {
    setActiveTransferAccordion(id);
  };

  const handleCancelListingOpen = () => setCancelListingModal(true);
  const handleCancelListingClose = () => setCancelListingModal(false);

  useEffect(() => {
    props.dispatch(clearNftSingleImageData());
    props.dispatch(clearNftSingleSellData());
    props.dispatch(clearCancelListingData());
    getCurrentOwner();
  }, [token_id]);

  const { validation } = useValidation(props.singleNftImage);

  const userAuthStatus = useUserAuthStatus(props.profile);

  const getCurrentOwner = async () => {
    let currentowneraddress = await getOwner(contract_address, token_id).then(
      (res) => res
    );
    console.log("currentowneraddress", currentowneraddress);
    setCurrentOwner(currentowneraddress);
    props.dispatch(
      fetchSingleNftItemsStart({
        nft_collection_unique_id: nft_collection_unique_id,
        nft_collection_item_token_id: token_id,
        owner_wallet_address:
          currentowneraddress == null || currentowneraddress == false
            ? ""
            : currentowneraddress,
        current_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );
  };

  const checkItemChainStatus = async () => {
    if (!auth.authStatus) {
      const notificationMessage = getErrorNotificationMessage(
        "Please connect metamask."
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }

    if (
      CHAIN_INFO[auth.chainId].nativeCurrency.symbol !=
      props.singleNftImage.data.nft_collection.blockchain_type
    ) {
      const notificationMessage = getErrorNotificationMessage(
        `Collection is not deployed in the detected network. Please switch to ${props.singleNftImage.data.nft_collection.blockchain_type}`
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }

    return true;
  };

  useEffect(() => {
    // if (!auth.loading && auth.accounts != "" && auth.authStatus) {
    getCurrentOwner();
    // }
  }, []);

  useEffect(() => {
    if (!validation.loading && !validation.status) {
      props.dispatch(
        moreFromCollectionStart({
          nft_collection_unique_id: nft_collection_unique_id,
          take: 4,
        })
      );
      props.dispatch(
        nftItemsPaymentListStart({
          nft_collection_item_token_id: token_id,
          nft_collection_unique_id: nft_collection_unique_id,
          skip: 0,
          take: 6,
        })
      );
      props.dispatch(
        nftItemFavouriteUsersListStart({
          nft_collection_unique_id: nft_collection_unique_id,
          nft_collection_item_token_id: token_id,
        })
      );
      props.dispatch(
        bidOfferNftItemListStart({
          nft_collection_unique_id: nft_collection_unique_id,
          nft_collection_item_token_id: token_id,
        })
      );
    }
  }, [validation]);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const handlePriceSortChange = (event) => {
    const {
      target: { value },
    } = event;

    setPriceSort(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    props.dispatch(
      nftItemsPaymentListStart({
        nft_collection_item_token_id: token_id,
        nft_collection_unique_id: nft_collection_unique_id,
        payment_type: value === "string" ? value.split(",") : value,
        skip: 0,
        take: 6,
      })
    );
  };

  const handleRemovePriceSort = (value) => {
    var payment_type = priceSort;
    payment_type.splice(payment_type.indexOf(value), 1);

    setPriceSort(payment_type);
    props.dispatch(
      nftItemsPaymentListStart({
        nft_collection_item_token_id: token_id,
        nft_collection_unique_id: nft_collection_unique_id,
        payment_type:
          payment_type === "string" ? payment_type.split(",") : payment_type,
        skip: 0,
        take: 6,
      })
    );
  };

  const [open, setOpen] = useState(false);

  const handleReportClose = () => setOpen(false);
  const handleReportOpen = () => setOpen(true);

  const [unReviewedModal, setUnReviewedModal] = useState(false);

  const handleUnReviewedClose = () => setUnReviewedModal(false);
  const handleUnReviewedOpen = () => {
    if (!auth.authStatus) {
      const notificationMessage = getErrorNotificationMessage(
        "Please connect metamask."
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }

    if (
      CHAIN_INFO[auth.chainId].nativeCurrency.symbol !=
      props.singleNftImage.data.nft_collection.blockchain_type
    ) {
      const notificationMessage = getErrorNotificationMessage(
        `Collection is not deployed in the detected network. Please switch to ${props.singleNftImage.data.nft_collection.blockchain_type}`
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }

    if (
      auth.accounts ==
      props.singleNftImage.data.nft_collection_item.owner_wallet_address
    ) {
      const notificationMessage = getErrorNotificationMessage(
        `You cannot buy your own NFT. Please switch to some other wallet`
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }
    setUnReviewedModal(true);
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Link was copied to clipboard!"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const [favoiriteUsersModal, setFavoiriteUserModal] = useState(false);

  const handlefavouriteUsersOpen = () => setFavoiriteUserModal(true);
  const handlefavouriteUsersClose = () => setFavoiriteUserModal(false);

  const [makeOfferModal, setMakeOfferModal] = useState(false);

  const handleMakeOfferClose = () => {
    props.dispatch(
      bidOfferNftItemListStart({
        nft_collection_unique_id: nft_collection_unique_id,
        nft_collection_item_token_id: token_id,
      })
    );
    setMakeOfferModal(false);
  };
  const handleMakeOfferOpen = async () => {
    let isCurrentChain = await checkItemChainStatus();
    if (isCurrentChain) {
      if (auth.accounts == currentOwner) {
        const notificationMessage = getErrorNotificationMessage(
          `You cannot make offer for your own NFT. Please switch to some other wallet`
        );
        props.dispatch(createNotification(notificationMessage));
        return false;
      }
      setMakeOfferModal(true);
    }
    console.log("Wrong chain", isCurrentChain);
  };

  useEffect(() => {
    if (
      !props.favouriteSave.loading &&
      props.singleNftImage.data.nft_collection_item
    ) {
      if (
        props.favouriteSave.data.success &&
        props.favouriteSave.data.data.token_id ==
        props.singleNftImage.data.nft_collection_item.token_id
      ) {
        if (props.favouriteSave.data.code == "114") {
          setFavouritesCount(favouritesCount + 1);
          setFavouriteStatus(true);
        } else {
          setFavouritesCount(favouritesCount - 1 < 0 ? 0 : favouritesCount - 1);
          setFavouriteStatus(false);
        }
      }
      props.dispatch(
        nftItemFavouriteUsersListStart({
          nft_collection_unique_id: nft_collection_unique_id,
          nft_collection_item_token_id: token_id,
        })
      );
    }
  }, [props.favouriteSave.data]);

  const handleLike = () => {
    props.dispatch(
      nftFavouritesSaveStart({
        nft_collection_unique_id: nft_collection_unique_id,
        nft_collection_item_token_id:
          props.singleNftImage.data.nft_collection_item.token_id,
      })
    );
  };

  useEffect(() => {
    if (
      !props.singleNftImage.loading &&
      props.singleNftImage.data.nft_collection_item
    ) {
      if (userAuthStatus.userLoggedIn) connectBlockchain();
      setFavouriteStatus(
        props.singleNftImage.data.nft_collection_item.is_favourite_item
          ? true
          : false
      );
      setFavouritesCount(
        props.singleNftImage.data.nft_collection_item.favourites_count
      );
      ethPrice("usd").then((data) => {
        const usdPrice = data.toString();
        const usdPriceFormatted = usdPrice.slice(5, usdPrice.length);
        setUsdPrice(usdPriceFormatted);
      });
      setLocalSingleNftImage(props.singleNftImage);
      // setPriceSelect(configuration.get("configData.native_currency_symbol").split(","));
    }
  }, [props.singleNftImage.data]);

  useEffect(() => {
    if (auth.accounts != "") {
      connectBlockchain();
    }
  }, [auth.accounts, auth.chainId]);

  // Blockchain code.

  // Get NFT data from the blockchain.
  const connectBlockchain = async () => {
    // NFT contract.
    const web3 = window.web3;
    let nftContractData;

    const NFTData = NftToken.networks[auth.chainId];
    let sellerContract;
    console.log("Connect blockcahin seller addres", auth.sellerContractAddress);
    if (NFTData && auth.accounts) {
      nftContractData = await new web3.eth.Contract(
        NftToken.abi,
        contract_address
      );
      sellerContract = await new web3.eth.Contract(
        SellerContract.abi,
        auth.sellerContractAddress
      );
      setSellerContract(sellerContract);
      setNftContractData(nftContractData);
    } else {
      console.log("NFT contract not deployed to detected network.");
    }
  };

  // Cancel blockchain code.

  const cancelBlockchain = async () => {
    if (props.singleNftImage.data.nft_collection_item.is_minted == 1) {
      if (!auth.authStatus) {
        const notificationMessage = getErrorNotificationMessage(
          "Please connect metamask."
        );
        props.dispatch(createNotification(notificationMessage));
        return false;
      }

      if (
        CHAIN_INFO[auth.chainId].nativeCurrency.symbol !=
        props.singleNftImage.data.nft_collection.blockchain_type
      ) {
        const notificationMessage = getErrorNotificationMessage(
          `Collection is not deployed in the detected network. Please switch to ${props.singleNftImage.data.nft_collection.blockchain_type}`
        );
        props.dispatch(createNotification(notificationMessage));
        return false;
      }

      if (
        auth.accounts !=
        props.singleNftImage.data.nft_collection_item.owner_wallet_address
      ) {
        const notificationMessage = getErrorNotificationMessage(
          `Collection is not deployed in the detected wallet address. Please switch to ${String(
            props.singleNftImage.data.nft_collection_item.owner_wallet_address
          ).substring(0, 5)}
        ...
        ${String(
            props.singleNftImage.data.nft_collection_item.owner_wallet_address
          ).substring(
            props.singleNftImage.data.nft_collection_item.owner_wallet_address
              .length - 4
          )}`
        );
        props.dispatch(createNotification(notificationMessage));
        return false;
      }
      setCancelListingButtonContent("Canceling please wait...");

      sellerContract.methods
        .withdrawSellOffer(token_id, contract_address)
        .send({
          gasPrice: '10000000000',
          from: auth.accounts,
        })
        .on("receipt", (receipt) => {
          console.log("Trans", receipt);
          // Api
          setCancelListingButtonContent("");
          props.dispatch(
            cancelListNftItemStart({
              nft_collection_item_unique_id:
                props.singleNftImage.data.nft_collection_item
                  .nft_collection_item_unique_id,
            })
          );
        })
        .on("error", (error) => {
          setCancelListingButtonContent("");
          console.log("Error", error);
          let notificationMessage;
          if (error.message == undefined) {
            notificationMessage = getErrorNotificationMessage(
              "Unexpected error occuried, Please try again..."
            );
            props.dispatch(createNotification(notificationMessage));
          } else {
            notificationMessage = getErrorNotificationMessage(error.message);
            props.dispatch(createNotification(notificationMessage));
          }
          // Send error notification.
        });
    } else {
      setCancelListingButtonContent("Canceling please wait...");
      props.dispatch(
        cancelListNftItemStart({
          nft_collection_item_unique_id:
            props.singleNftImage.data.nft_collection_item
              .nft_collection_item_unique_id,
        })
      );
    }
  };

  // transfer money from buyer account to seller account and once the amount is received then we need to send the NFT to the buyer account

  const sendMoneyToCreator = async () => {
    setButtonContent("Purchase under progress...");
    let paymentAmount =
      props.singleNftImage.data.nft_collection_item.amount.toString();
    console.log("paymentAmount ", paymentAmount);
    const web3 = window.web3;
    if (
      props.singleNftImage.data.nft_collection.collection_type == 2 &&
      props.singleNftImage.data.nft_collection_item.is_minted == 0
    ) {
      paymentAmount = window.web3.utils.toWei(String(paymentAmount), "Ether");
      let royaltyAddress =
        props.singleNftImage.data.nft_collection.deployed_wallet_address;
      let royaltyPercentage = "0";
      if (
        props.singleNftImage.data.nft_collection.royalty_type == 1 &&
        props.singleNftImage.data.nft_collection.royalty_percentage > 0
      ) {
        royaltyAddress =
          props.singleNftImage.data.nft_collection.royalty_wallet_address;
        royaltyPercentage = String(
          Number(props.singleNftImage.data.nft_collection.royalty_percentage) *
          10
        );
      } else if (
        props.singleNftImage.data.nft_collection_item.royalty_percentage > 0
      ) {
        royaltyAddress =
          props.singleNftImage.data.nft_collection_item.royalty_wallet_address;
        royaltyPercentage = String(
          Number(
            props.singleNftImage.data.nft_collection_item.royalty_percentage
          ) * 10
        );
      }
      let lazyNftContractData = await new web3.eth.Contract(
        LazyNft.abi,
        contract_address
      );
      console.log("lazyNftContractData", lazyNftContractData);
      let voucher = {
        tokenId: token_id,
        minPrice: paymentAmount,
        uri: props.singleNftImage.data.nft_collection_item.token_uri,
        royaltyAddress: royaltyAddress,
        royaltyPercentage: royaltyPercentage,
        signature:
          props.singleNftImage.data.nft_collection_item.sign_transaction_hash,
      };
      console.log("voucher", voucher);
      lazyNftContractData.methods
        .redeem(props.profile.data.user.wallet_address, voucher)
        .send({
          gasPrice: '10000000000',
          from: auth.accounts,
          value: paymentAmount,
        })
        .on("error", (error) => {
          let notificationMessage;
          if (error.message == undefined) {
            notificationMessage = "Something went wrong, please try again!";
          } else {
            notificationMessage = error.message;
          }
          setButtonContent("");
        })
        .once("receipt", (receipt) => {
          // sendTransactionApi(
          //   receipt.transactionHash,
          // );
          console.log("Receipt", receipt);
          props.dispatch(
            buyNftItemStart({
              nft_collection_item_unique_id: nft_collection_item_unique_id,
              transaction_hash:
                receipt && receipt.transactionHash
                  ? receipt.transactionHash
                  : "",
              contract_address: contract_address,
              payment_mode: paymentMode,
            })
          );
          setButtonContent("");
        });
    } else {
      const seller_wallet_address =
        !props.singleNftImage.loading &&
          props.singleNftImage.data.nft_collection_item.recent_buyer
          ? props.singleNftImage.data.nft_collection_item.recent_buyer
            .wallet_address
          : "";

      if (seller_wallet_address != "") {
        setButtonContent((prevState) => "Purchase under progress...");

        sellerContract.methods
          .purchase(token_id, contract_address, userAuthStatus.wallet_address)
          .send({
            gasPrice: '10000000000',
            from: auth.accounts,
            value: window.web3.utils.toWei(paymentAmount, "Ether"),
          })
          .on("receipt", (receipt) => {
            console.log("Trans", receipt);
            props.dispatch(
              buyNftItemStart({
                nft_collection_item_unique_id: nft_collection_item_unique_id,
                transaction_hash:
                  receipt && receipt.transactionHash
                    ? receipt.transactionHash
                    : "",
                contract_address: contract_address,
                price_type: configuration.get(
                  "configData.native_currency_symbol"
                ),
                payment_mode: paymentMode,
              })
            );
          })
          .on("error", (error) => {
            setButtonContent("");
            console.log("Error", error);
            let notificationMessage;
            if (error.message == undefined) {
              notificationMessage = getErrorNotificationMessage(
                "Unexpected error occuried, Please try again..."
              );
              props.dispatch(createNotification(notificationMessage));
            } else {
              notificationMessage = getErrorNotificationMessage(error.message);
              props.dispatch(createNotification(notificationMessage));
            }
            // Send error notification.
          });
      } else {
        setButtonContent("");
        // Send fail notification.
        const notificationMessage = getErrorNotificationMessage(
          "Seller is Unavailable."
        );
        props.dispatch(createNotification(notificationMessage));
        // window.alert("Seller is Unavailable.");
      }
    }
  };

  useEffect(() => {
    if (buttonContent != "") {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [buttonContent]);

  useEffect(() => {
    if (
      !props.buyNftItem.loading &&
      props.buyNftItem.data.code == 121 &&
      props.singleNftImage.data.nft_collection_item
    ) {
      // handleUnReviewedClose();
      props.dispatch(clearNftSingleSellData());
      props.dispatch(clearCancelListingData());
      // props.dispatch(
      //   fetchSingleNftItemsStart({
      //     nft_collection_contract_address: contract_address,
      //     nft_collection_item_token_id: token_id,
      //     owner_wallet_address : currentOwner
      //   })
      // );
      getCurrentOwner();
      props.dispatch(
        nftItemsPaymentListStart({
          nft_collection_item_token_id: token_id,
          nft_collection_unique_id: nft_collection_unique_id,
          skip: 0,
          take: 6,
        })
      );
    } else if (
      !props.buyNftItem.loading &&
      Object.keys(props.buyNftItem.data).length <= 0
    ) {
      setButtonContent("");
    }
  }, [props.buyNftItem.data]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleTransferModalOpen = () => {
    setTranseferModal({
      ...transferModal,
      status: true,
    });
  };

  const handleTransferModalClose = () => {
    setTranseferModal({
      ...transferModal,
      status: false,
    });
  };

  // useEffect(() => {
  //   handleTransferModalClose();
  //   handlefavouriteUsersClose();
  //   handleCancelListingClose();
  //   handleReportClose();
  //   handlefavouriteUsersClose();
  // }, [auth.accounts]);

  const handlePropertySelect = (property) => {
    const query = queryString.stringify(
      {
        property: [
          JSON.stringify({ name: property.name, value: [property.value] }),
        ],
      },
      { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" }
    );

    navigate({
      pathname: `/collection/${props.singleNftImage.data.nft_collection.nft_collection_unique_id}`,
      search: query,
    });
  };

  useEffect(() => {
    if (
      !props.transferNft.loading &&
      Object.keys(props.transferNft.data).length > 0
    ) {
      // props.dispatch(
      //   fetchSingleNftItemsStart({
      //     nft_collection_contract_address: contract_address,
      //     nft_collection_item_token_id: token_id,
      //   })
      // );
      setSignData({
        ...signData,
        signature: null,
        status: false,
        loading: false,
        loadingContent: null,
      });
      getCurrentOwner();
      props.dispatch(clearTransferNftData());
      handleTransferModalClose();
    }
  }, [props.transferNft.loading]);

  const getOwner = async (contract_address, token_id) => {
    const web3 = new Web3(Web3.givenProvider || "http://localhost:3000");
    let nftContractData;
    let ownerAddress;

    const NFTData = NftToken.networks[auth.chainId];
    if (NFTData) {
      try {
        nftContractData = await new web3.eth.Contract(
          NftToken.abi,
          contract_address
        );
        ownerAddress = await nftContractData.methods.ownerOf(token_id).call();
        return ownerAddress;
      } catch (error) {
        console.log(error);
        return false;
      }
    }
  };

  const makeOfferSave = async (offerData) => {
    let bidExpire = `${moment(offerData.bid_expire_at).format("YYYY-MM-DD")} ${offerData.endTime
      }:00`;

    setMakeOfferButtonContent("Initiated...");

    const web3 = window.web3;
    let marketPlaceContractData;

    console.log("sellerContractAddress", auth.sellerContractAddress);

    marketPlaceContractData = await new web3.eth.Contract(
      MarketPlaceContract.abi,
      auth.sellerContractAddress
    );

    bidExpire = Date.parse(bidExpire) / 1000;

    const paymentAmount = offerData.amount.toString();

    try {
      marketPlaceContractData.methods
        .makeBuyOffer(token_id, contract_address, bidExpire, auth.accounts)
        .send({
          gasPrice: '10000000000',
          from: auth.accounts,
          value: window.web3.utils.toWei(paymentAmount, "Ether"),
        })
        .on("receipt", (receipt) => {
          props.dispatch(
            bidOfferNftItemSaveStart({
              nft_collection_unique_id: nft_collection_unique_id,
              nft_collection_item_token_id: token_id,
              amount: offerData.amount,
              wallet_address: auth.accounts,
              bid_expire_at: `${moment(offerData.bid_expire_at).format(
                "YYYY-MM-DD"
              )} ${offerData.endTime}:00`,
            })
          );
          // Send success notification.
        })
        .on("error", (error) => {
          let errorMessage;
          if (error.message == undefined) {
            errorMessage = "Unexpected error occuried, Please try again...";
          } else {
            errorMessage = error.message;
          }
          let notificationMessage = getErrorNotificationMessage(errorMessage);
          props.dispatch(createNotification(notificationMessage));
          setMakeOfferButtonContent("");
          // Send error notification.
        });
    } catch (error) {
      let notificationMessage = getErrorNotificationMessage(
        "Something went wrong"
      );
      props.dispatch(createNotification(notificationMessage));
      setMakeOfferButtonContent("");
    }
  };

  const approveOfferSave = async (acceptOfferInput) => {
    let isCurrentChain = await checkItemChainStatus();

    if (isCurrentChain) {
      if (auth.accounts != currentOwner) {
        const notificationMessage = getErrorNotificationMessage(
          `Connected wallet is not the owner of this NFT. Please switch to ${String(
            currentOwner
          ).substring(0, 5)}
          ...
          ${String(currentOwner).substring(currentOwner.length - 4)}`
        );
        props.dispatch(createNotification(notificationMessage));
        return false;
      }
      setAcceptOfferBtnContent("Initiated...");

      const web3 = window.web3;
      let marketPlaceContractData;

      marketPlaceContractData = await new web3.eth.Contract(
        MarketPlaceContract.abi,
        auth.sellerContractAddress
      );

      try {
        marketPlaceContractData.methods
          .acceptBuyOffer(token_id, contract_address)
          .send({ gasPrice: '10000000000', from: auth.accounts })
          .on("receipt", (receipt) => {
            props.dispatch(acceptOfferNftItemStart(acceptOfferInput));
            // Send success notification.
          })
          .on("error", (error) => {
            let errorMessage;
            if (error.message == undefined) {
              errorMessage = "Unexpected error occuried, Please try again...";
            } else {
              errorMessage = error.message;
            }
            let notificationMessage = getErrorNotificationMessage(errorMessage);
            props.dispatch(createNotification(notificationMessage));
            setAcceptOfferBtnContent("");
            // Send error notification.
          });
      } catch (error) {
        let notificationMessage = getErrorNotificationMessage(
          "Something went wrong"
        );
        props.dispatch(createNotification(notificationMessage));
        setAcceptOfferBtnContent("");
      }
    }
  };

  const cancelOfferSave = async (cancelOfferInput) => {
    let isCurrentChain = await checkItemChainStatus();

    if (isCurrentChain) {
      if (auth.accounts != cancelOfferInput.wallet_address) {
        const notificationMessage = getErrorNotificationMessage(
          `Connected wallet is not the offered wallet. Please switch to ${String(
            cancelOfferInput.wallet_address
          ).substring(0, 5)}
          ...
          ${String(cancelOfferInput.wallet_address).substring(
            cancelOfferInput.wallet_address.length - 4
          )}`
        );
        props.dispatch(createNotification(notificationMessage));
        return false;
      }
      setCancelOfferBtnContent("Initiated...");

      const web3 = window.web3;
      let marketPlaceContractData;

      marketPlaceContractData = await new web3.eth.Contract(
        MarketPlaceContract.abi,
        auth.sellerContractAddress
      );

      try {
        marketPlaceContractData.methods
          .withdrawBuyOffer(token_id, contract_address, auth.accounts)
          .send({ gasPrice: '10000000000', from: auth.accounts })
          .on("receipt", (receipt) => {
            props.dispatch(cancelBidOfferNftItemStart(cancelOfferInput));
            // Send success notification.
          })
          .on("error", (error) => {
            let errorMessage;
            if (error.message == undefined) {
              errorMessage = "Unexpected error occuried, Please try again...";
            } else {
              errorMessage = error.message;
            }
            let notificationMessage = getErrorNotificationMessage(errorMessage);
            props.dispatch(createNotification(notificationMessage));
            setCancelOfferBtnContent("");
            // Send error notification.
          });
      } catch (error) {
        console.log("error", error);
        let notificationMessage = getErrorNotificationMessage(
          "Something went wrong"
        );
        props.dispatch(createNotification(notificationMessage));
        setCancelOfferBtnContent("");
      }
    }
  };

  const handleredirectLogin = () => navigate("/login");

  const buyNftByFiat = async () => {
    setButtonContent("Initiated...");
    const web3 = window.web3;
    const seller_wallet_address =
      !props.singleNftImage.loading &&
        props.singleNftImage.data.nft_collection_item.recent_buyer
        ? props.singleNftImage.data.nft_collection_item.recent_buyer
          .wallet_address
        : "";

    const paymentAmount =
      props.singleNftImage.data.nft_collection_item.amount.toString();

    console.log("paymentAmount ", paymentAmount);

    if (seller_wallet_address != "") {
      setButtonContent((prevState) => "Loading...");

      props.dispatch(
        buyNftItemStart({
          nft_collection_item_unique_id: nft_collection_item_unique_id,
          contract_address: contract_address,
          payment_mode: paymentMode,
        })
      );
    } else {
      setButtonContent("");
      // Send fail notification.
      const notificationMessage = getErrorNotificationMessage(
        "Seller is Unavailable."
      );
      props.dispatch(createNotification(notificationMessage));
      // window.alert("Seller is Unavailable.");
    }
  };

  return (
    <>
      {props.singleNftImage.loading ? (
        <SingleNftImageLoader />
      ) : Object.keys(props.singleNftImage.data).length > 0 &&
        props.singleNftImage.data.nft_collection_item ? (
        <>
          {!props.profile.loading &&
            Object.keys(props.profile.data).length > 0 &&
            props.singleNftImage.data.nft_collection_item.recent_buyer_id ==
            props.profile.data.user.user_id ? (
            <div className="single-page-btn">
              <Container>
                <div className="single-page-link">
                  {props.singleNftImage.data.nft_collection_item
                    .list_btn_status ? (
                    <Link
                      to={`/sell-item/${nft_collection_unique_id}/${props.singleNftImage.data.nft_collection.contract_address}/${props.singleNftImage.data.nft_collection_item.token_id}`}
                      className="default-btn"
                    >
                      Sell
                    </Link>
                  ) : null}
                  {props.singleNftImage.data.nft_collection_item
                    .cancel_list_btn_status == 1 ? (
                    <Link
                      // onClick={() => setCancelListing(true)}
                      onClick={() => handleCancelListingOpen()}
                      className="default-outline-btn"
                    >
                      Cancel listing
                    </Link>
                  ) : null}
                </div>
              </Container>
            </div>
          ) : null}
          <div className="single-item-view-sec">
            <Container>
              <div className="single-item-view-header-sec">
                <div className="item-list-main-back-btn">
                  <Image
                    onClick={() => navigate(-1)}
                    src={
                      window.location.origin +
                      "/images/landing-page/icons/back-arrow.svg"
                    }
                    className="back-arrow-icon"
                  />
                </div>
              </div>
              <Row className="g-4">
                <Col md={6}>
                  <div className="single-item-preview-img-card">
                    <div className="single-item-preview-img-sec">
                      {props.singleNftImage.data.nft_collection_item
                        .file_type === 1 ? (
                        <Image
                          src={
                            props.singleNftImage.data.nft_collection_item
                              .picture != ""
                              ? props.singleNftImage.data.nft_collection_item
                                .picture
                              : configuration.get("configData.item_placholder")
                          }
                          className="single-item-preview-img"
                        />
                      ) : (
                        <iframe
                          className="dynamic-nft-preview single-item-preview-img"
                          srcdoc={
                            props.singleNftImage.data.nft_collection_item
                              .animation_url
                          }
                        />
                      )}
                    </div>
                    {/* <div className="single-item-sub-title">
                      <Image
                        src={
                          window.location.origin +
                          "/images/create-item/icons/insta.svg"
                        }
                        className="single-item-icon"
                      />
                      <span>NFT</span>
                    </div> */}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="single-item-info-box">
                    <div className="single-item-info-header-sec">
                      <div className="single-item-info-details">
                        <h2>
                          {props.singleNftImage.data.nft_collection_item.name}
                        </h2>
                        <div className="single-item-info-followers">
                          <Image
                            src={
                              favouriteStatus
                                ? window.location.origin +
                                "/images/landing-page/icons/heart-fill.svg"
                                : window.location.origin +
                                "/images/landing-page/icons/heart-outline.svg"
                            }
                            className="wishlist-icon"
                            onClick={
                              userAuthStatus.userLoggedIn
                                ? () => handleLike()
                                : () => navigate("/login")
                            }
                          />
                          <span
                            onClick={() => setFavoritedBy(true)}
                            className="cursor-pointer"
                          >
                            {favouritesCount}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="single-item-from-sec">
                      <span>from</span>
                      <Link
                        to={`/collection/${props.singleNftImage.data.nft_collection.nft_collection_unique_id}`}
                        className="single-item-from-link"
                      >
                        {props.singleNftImage.data.nft_collection.name}
                      </Link>
                    </div>
                    <div className="single-item-description-box">
                      <div className="single-item-description-sec">
                        {props.singleNftImage.data.nft_collection_item
                          .description != "" &&
                          props.singleNftImage.data.nft_collection_item
                            .description != null ? (
                          <>
                            <h5>Description</h5>
                            <div className={`long-text ${clamped ? "clamp" : ""}`}>
                              <p>
                                {props.singleNftImage.data.nft_collection_item
                                  .description != "" &&
                                  props.singleNftImage.data.nft_collection_item
                                    .description != null
                                  ? props.singleNftImage.data.nft_collection_item
                                    .description
                                  : "No description"}
                              </p>
                            </div>

                            {props.singleNftImage.data.nft_collection_item
                              .description != "" &&
                              props.singleNftImage.data.nft_collection_item
                                .description != null &&
                              props.singleNftImage.data.nft_collection_item
                                .description.length > 345 &&
                              showReadButton ? (
                              <Button
                                className="read-more-btn"
                                onClick={handleReadClick}
                              >
                                Read {clamped ? "more" : "less"}
                              </Button>
                            ) : null}
                          </>
                        ) : null}
                        <div className="single-item-owned-by-sec">
                          {props.singleNftImage.data.nft_collection_item
                            .user && (
                              <div className="single-item-owned-by-card">
                                <Image
                                  src={
                                    props.singleNftImage.data.nft_collection_item
                                      .user.picture
                                  }
                                  className="single-item-owned-user-img"
                                />
                                <Link
                                  to={
                                    props.singleNftImage.data.nft_collection_item
                                      .recent_buyer_id === userAuthStatus.userId
                                      ? `/profile`
                                      : `/account/${props.singleNftImage.data.nft_collection_item.user.user_unique_id}`
                                  }
                                >
                                  @
                                  {
                                    props.singleNftImage.data.nft_collection_item
                                      .user.username
                                  }
                                </Link>
                              </div>
                            )}
                          {props.singleNftImage.data.nft_collection_item
                            .recent_buyer && (
                              <div className="single-item-owned-by-card">
                                <Image
                                  src={
                                    props.singleNftImage.data.nft_collection_item
                                      .recent_buyer.picture
                                  }
                                  className="single-item-owned-user-img"
                                />
                                <span>Owned by</span>
                                <Link
                                  to={
                                    props.singleNftImage.data.nft_collection_item
                                      .recent_buyer_id === userAuthStatus.userId
                                      ? `/profile`
                                      : `/account/${props.singleNftImage.data.nft_collection_item.recent_buyer.user_unique_id}`
                                  }
                                  className="single-item-owned-id"
                                >
                                  {
                                    props.singleNftImage.data.nft_collection_item
                                      .recent_buyer.wallet_address_formatted
                                  }
                                </Link>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="single-item-right-list-action-btn-sec">
                        <ul className="list-unstyled single-item-right-list-action-btn">
                          {props.singleNftImage.data.nft_collection_item
                            .external_link != "" && (
                              <li>
                                <a
                                  href={
                                    props.singleNftImage.data.nft_collection_item
                                      .external_link
                                  }
                                  target="_blank"
                                >
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/external-link.svg"
                                    }
                                    className="list-action-icon"
                                  />
                                </a>
                              </li>
                            )}
                          <li>
                            <ClickAwayListener
                              mouseEvent="onMouseDown"
                              touchEvent="onTouchStart"
                              onClickAway={handleProfileAway}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  display: "contents",
                                }}
                              >
                                <Link type="button" onClick={handleProfile}>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/single-item/icons/share.svg"
                                    }
                                    className="list-action-icon"
                                  />
                                </Link>
                                {profileOpen ? (
                                  <Box sx={profile}>
                                    <div className="profile-list-section share-list">
                                      <ul>
                                        <li>
                                          <CopyToClipboard
                                            text={
                                              window.location.origin +
                                              location.pathname
                                            }
                                            onCopy={onCopy}
                                          >
                                            <Link to="#">
                                              <span>
                                                {" "}
                                                <Image
                                                  src={
                                                    window.location.origin +
                                                    "/images/icons/copy-solid.svg"
                                                  }
                                                />
                                              </span>
                                              Copy link
                                            </Link>
                                          </CopyToClipboard>
                                        </li>
                                        <li>
                                          <FacebookShareButton
                                            url={
                                              window.location.origin +
                                              location.pathname
                                            }
                                            quote={
                                              window.location.origin +
                                              location.pathname
                                            }
                                            className="Demo__some-network__share-button"
                                          >
                                            <Link to="#">
                                              <span>
                                                {" "}
                                                <Image
                                                  src={
                                                    window.location.origin +
                                                    "/images/icons/facebook-solid.svg"
                                                  }
                                                />
                                              </span>
                                              Share on Facebook
                                            </Link>
                                          </FacebookShareButton>
                                        </li>
                                        <li>
                                          <TwitterShareButton
                                            url={
                                              window.location.origin +
                                              location.pathname
                                            }
                                            className="Demo__some-network__share-button"
                                          >
                                            <Link to="#">
                                              <span>
                                                {" "}
                                                <Image
                                                  src={
                                                    window.location.origin +
                                                    "/images/icons/twitter-solid.svg"
                                                  }
                                                />
                                              </span>
                                              Share on Twitter
                                            </Link>
                                          </TwitterShareButton>
                                        </li>
                                      </ul>
                                    </div>
                                  </Box>
                                ) : null}
                              </Box>
                            </ClickAwayListener>
                          </li>
                          {userAuthStatus.userLoggedIn &&
                            !props.singleNftImage.loading ? (
                            props.singleNftImage.data.nft_collection_item
                              .recent_buyer_id === userAuthStatus.userId &&
                              props.singleNftImage.data.nft_collection_item
                                .is_minted == 1 ? (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() => handleTransferModalOpen()}
                                >
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/single-item/icons/gift.svg"
                                    }
                                    className="list-action-icon"
                                  />
                                </Link>
                              </li>
                            ) : null
                          ) : null}
                          <li>
                            <Link
                              to="#"
                              onClick={() => setReportCollection(true)}
                            >
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/single-item/icons/flag.svg"
                                }
                                className="list-action-icon"
                              />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {props.singleNftImage.data.nft_collection_item.is_listed ==
                      1 &&
                      props.singleNftImage.data.nft_collection_item.amount > 0 &&
                      props.singleNftImage.data.nft_collection_item
                        .is_listing_expired != 1 ? (
                      <div className="single-item-price-box">
                        <div className="single-item-price-card">
                          <div className="single-item-price-amount">
                            <Image
                              src={
                                CHAIN_INFO[
                                  SupportedChainIdBySymbol[
                                  props.singleNftImage.data
                                    .nft_collection_item.blockchain_type
                                  ]
                                ].logo
                              }
                              className="single-item-price-icon"
                            />
                            <span>
                              {
                                props.singleNftImage.data.nft_collection_item
                                  .amount
                              }
                            </span>
                          </div>
                          <div className="single-item-price-type">
                            <span>
                              {
                                props.singleNftImage.data.nft_collection_item
                                  .blockchain_type
                              }
                            </span>
                          </div>
                        </div>
                        <div className="single-item-price-card">
                          <div className="single-item-price-amount">
                            <Image
                              src={
                                window.location.origin +
                                "/images/single-item/icons/dollar.svg"
                              }
                              className="single-item-price-icon"
                            />
                            <span>
                              {price.usdConvertedPrice != null &&
                                props.singleNftImage.data.nft_collection_item
                                  .amount != "" &&
                                Number(
                                  props.singleNftImage.data.nft_collection_item
                                    .amount
                                ) > 0 ? (
                                <p className="converted_price right m-0">
                                  ≉{" "}
                                  <i
                                    className="fa fa-usd"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {Number(
                                    props.singleNftImage.data
                                      .nft_collection_item.amount *
                                    price.usdConvertedPrice
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })}{" "}
                                  {price.denomination}
                                </p>
                              ) : (
                                "0"
                              )}
                            </span>
                          </div>
                          <div className="single-item-price-type">
                            <span>FIAT</span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="single-item-btn-sec">
                      {props.singleNftImage.data.nft_collection_item
                        .buy_btn_status == 1 &&
                        props.singleNftImage.data.nft_collection_item
                          .is_listing_started == 1 &&
                        props.singleNftImage.data.nft_collection_item
                          .is_listing_expired != 1 && (
                          <>
                            <Button
                              className="default-btn"
                              // onClick={() => setBuyNFT(true)}
                              disabled={
                                props.singleNftImage.data.nft_collection_item
                                  .is_sold == 1 || buttonContent != ""
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                userAuthStatus.userLoggedIn
                                  ? handleUnReviewedOpen()
                                  : handleredirectLogin()
                              }
                            >
                              {buttonContent != ""
                                ? buttonContent
                                : `BUY FOR ${props.singleNftImage.data.nft_collection_item.amount} ${props.singleNftImage.data.nft_collection_item.blockchain_type}`}
                            </Button>

                            <Button
                              className="default-btn"
                              disabled={
                                props.singleNftImage.data.nft_collection_item
                                  .is_sold == 1 || buttonContent != ""
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                userAuthStatus.userLoggedIn
                                  ? buyNftByFiat()
                                  : handleredirectLogin()
                              }
                            >
                              {buttonContent != ""
                                ? buttonContent
                                : "BUY IN FIAT"}
                            </Button>
                          </>
                        )}
                      {props.singleNftImage.data.nft_collection_item
                        .recent_buyer_id != userAuthStatus.userId &&
                        (props.singleNftImage.data.nft_collection
                          .collection_type != 2 ||
                          props.singleNftImage.data.nft_collection_item
                            .is_minted == 1) &&
                        paymentMode == "CRYPTO" && (
                          <Button
                            className="make-offer-btn"
                            // onClick={() => setMakeOffer(true)}
                            disabled={
                              makeOfferButtonContent != "" ||
                                props.singleNftImage.data.nft_collection
                                  .is_verified == 0
                                ? true
                                : false
                            }
                            onClick={() =>
                              userAuthStatus.userLoggedIn
                                ? handleMakeOfferOpen()
                                : handleredirectLogin()
                            }
                          >
                            {makeOfferButtonContent != ""
                              ? makeOfferButtonContent
                              : "MAKE AN OFFER"}
                          </Button>
                        )}
                    </div>
                  </div>
                </Col>
              </Row>
              {props.singleNftImage.data.nft_collection_item
                .properties_formatted.length > 0 && (
                  <div className="single-item-properties-sec">
                    <Accordion defaultActiveKey={["0"]} alwaysOpen>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Properties{" "}
                          <span className="count-properties">
                            {
                              props.singleNftImage.data.nft_collection_item
                                .properties_formatted.length
                            }
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="single-item-properties-box">
                            {props.singleNftImage.data.nft_collection_item.properties_formatted.map(
                              (property, index) => (
                                <div className="single-item-properties-card">
                                  <span>
                                    {property.name}: {property.value}
                                  </span>
                                </div>
                              )
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                )}
              <ActivityOffersIndex
                singleNftImage={props.singleNftImage}
                nft_collection_unique_id={nft_collection_unique_id}
                nft_collection_item_token_id={token_id}
                getCurrentOwner={getCurrentOwner}
                cancelOfferSave={cancelOfferSave}
                cancelOfferBtnContent={cancelOfferBtnContent}
                setCancelOfferBtnContent={setCancelOfferBtnContent}
                setAcceptOfferBtnContent={setAcceptOfferBtnContent}
                acceptOfferBtnContent={acceptOfferBtnContent}
                currentOwner={currentOwner}
                approveOfferSave={approveOfferSave}
                nftItemsPaymentList={props.nftItemsPaymentList}
              />
              {props.itemsList.loading
                ? ""
                : props.itemsList.data.nft_collection_items.filter(
                  (collectionitem) => collectionitem.token_id != token_id
                ).length > 1 && (
                  <MoreFromCollection
                    nft_collection_items={
                      props.itemsList.data.nft_collection_items
                    }
                    nft_collection_unique_id={nft_collection_unique_id}
                  />
                )}
            </Container>
          </div>
          {cancelListingModal && (
            <CancelListingModal
              open={cancelListingModal}
              handleCancelListingClose={handleCancelListingClose}
              singleNftImage={props.singleNftImage}
              auth={auth}
              signData={signData}
              setSignData={setSignData}
              activeAccordion={activeCancelListingAccordion}
              toggleAccordion={toggleActiveAccordion}
              cancelBlockchain={cancelBlockchain}
              loadingButtonContent={cancelListingButtonContent}
            />
          )}
          <FavoritedByModal
            favoritedBy={favoritedBy}
            closeFavoritedByModal={closeFavoritedByModal}
            setFavoritedBy={setFavoritedBy}
            auth={auth}
            profile={props.profile}
            nft_collection_unique_id={nft_collection_unique_id}
            nft_collection_item_token_id={token_id}
          />
          {makeOfferModal && (
            <MakeofferModal
              open={makeOfferModal}
              handleMakeOfferClose={handleMakeOfferClose}
              singleNftImage={props.singleNftImage}
              makeOfferButtonContent={makeOfferButtonContent}
              setMakeOfferButtonContent={setMakeOfferButtonContent}
              contract_address={contract_address}
              token_id={token_id}
              getCurrentOwner={getCurrentOwner}
              makeOfferSave={makeOfferSave}
            />
          )}
          {transferModal && (
            <TransferNFTModal
              transferNFT={transferNFT}
              closeTransferNFTModal={closeTransferNFTModal}
              setTransferNFT={setTransferNFT}
              open={transferModal.status}
              handleClose={handleTransferModalClose}
              singleNftImage={props.singleNftImage}
              activeAccordion={activeTransferAccordion}
              toggleAccordion={toggleActiveTransferAccordion}
              signData={signData}
              setSignData={setSignData}
            />
          )}
          <ReportCollectionModal
            reportCollection={reportCollection}
            closeReportCollectionModal={closeReportCollectionModal}
            setReportCollection={setReportCollection}
            contract_address={contract_address}
            token_id={token_id}
          />
        </>
      ) : props.singleNftImage.error ||
        props.itemsList.error ||
        props.nftItemsPaymentList.error ? (
        <PageNotFound />
      ) : null}

      {unReviewedModal && (
        <BuyNFTModal
          open={unReviewedModal}
          handleUnReviewedClose={handleUnReviewedClose}
          singleNftImage={localSingleNftImage}
          priceFormatted={usdPrice}
          buttonContent={buttonContent}
          sendMoneyToCreator={sendMoneyToCreator}
          priceSelect={priceSelect}
          setPriceSelect={setPriceSelect}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  singleNftImage: state.nfts.singleItems,
  itemsList: state.nfts.moreFromCollection,
  nftItemsPaymentList: state.nfts.nftItemsPaymentList,
  itemFavouriteUsers: state.nfts.itemFavouriteUsers,
  favouriteSave: state.nfts.favouriteSave,
  buyNftItem: state.nfts.buyNftItem,
  transferNft: state.nfts.transferNft,
  bidOfferNftItemList: state.nfts.bidOfferNftItemList,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleNftImage);
