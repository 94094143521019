import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  fetchUserDetailsStart,
  userLoginStart,
} from "../../store/actions/UserAction";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CustomSearch from "../../customComponents/CustomSearch";
import AuthWallet from "../../authprovider/AuthWallet";
import { fetchCategoriesListStart } from "../../store/actions/NftAction";
import configuration from "react-global-configuration";
import { useAuth0 } from "@auth0/auth0-react";

const exceptionPaths = ["/view-private-key", "/logout"];

const HeaderIndex = (props) => {
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } =
    useAuth0();

  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [resources, setResources] = React.useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const [walletSwich, setWalletSwich] = useState(1);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleProfile = () => {
    setProfileOpen((prev) => !prev);
  };

  const handleProfileAway = () => {
    setProfileOpen(false);
  };

  const handleSearchQueryChange = (value) => {
    setSearchQuery(value);
  };

  const handleResources = () => {
    setResources((prev) => !prev);
  };

  const handleResourcesAway = () => {
    setResources(false);
  };

  const profile = {
    position: "absolute",
    width: 240,
    top: 60,
    right: 0,
    left: -200,
    zIndex: 9999,
    border: "1px solid transparent",
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  };
  const resourcesStyle = {
    position: "absolute",
    width: 190,
    top: 60,
    right: 0,
    left: -100,
    zIndex: 9999,
    border: "1px solid transparent",
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  };

  useEffect(() => {
    props.dispatch(fetchCategoriesListStart());
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      props.dispatch(fetchUserDetailsStart());
    }
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !props.profile.loading &&
      Object.keys(props.profile.data).length > 0
    ) {
      if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
        if (props.profile.data.user.onboarding_step < 6 && !exceptionPaths.includes(location.pathname)) {
          navigate("/onboarding");
        }
      }
    }
    setSkipRender(false);
  }, [props.profile]);

  return (
    <div>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container>
            <Link to="/" className="navbar-brand">
              <Image
                className="logo"
                src={window.location.origin + "/images/logo.png"}
              />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <Image
                className="hamburger-menu"
                src={
                  window.location.origin + "/images/icons/hamburger-menu.svg"
                }
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="align-items-center ms-auto">
                <CustomSearch
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                  classes="hide-mobile"
                />

                {/* <div className="header-search">
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      <Image
                        src={
                          window.location.origin +
                          "/images/landing-page/icons/search-icon.svg"
                        }
                      />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Search"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </div> */}
              </Nav>

              <Nav className="ms-auto">
                <div className="header-icons">
                  {/* {!isLoading && ( */}
                  <ul className="header-icons-list">
                    {localStorage.getItem("userId") &&
                    localStorage.getItem("accessToken") ? (
                      <>
                        {/* <li>
                          <ClickAwayListener
                            mouseEvent="onMouseDown"
                            touchEvent="onTouchStart"
                            onClickAway={handleResourcesAway}
                          >
                            <Box sx={{ position: "relative" }}>
                              <button
                                type="button"
                                className="resources-btn"
                                onClick={handleResources}
                              >
                                Resources
                              </button>
                              {resources ? (
                                <Box sx={resourcesStyle}>
                                  <div className="profile-list-section">
                                    <ul>
                                      {configuration
                                        .get("configData.footer_pages1")
                                        .map((static_page, index) => (
                                          <li key={index}>
                                            <Link
                                              to={`/page/${static_page.static_page_unique_id}`}
                                            >
                                              {static_page.title}
                                            </Link>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                </Box>
                              ) : null}
                            </Box>
                          </ClickAwayListener>
                        </li> */}

                        <li>
                          <ClickAwayListener
                            mouseEvent="onMouseDown"
                            touchEvent="onTouchStart"
                            onClickAway={handleProfileAway}
                          >
                            <Box sx={{ position: "relative" }}>
                              <button type="button" onClick={handleProfile}>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/landing-page/icons/profile-icon.svg"
                                  }
                                />
                              </button>
                              {profileOpen ? (
                                <Box sx={profile}>
                                  <div className="profile-list-section">
                                    <ul>
                                      <li>
                                        <Link to="/profile">
                                          <span>
                                            {" "}
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/images/landing-page/icons/profile-icon.svg"
                                              }
                                            />
                                          </span>
                                          Profile
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/buy-tokens">
                                          <span>
                                            {" "}
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/images/landing-page/icons/wallet-icon.svg"
                                              }
                                            />
                                          </span>
                                          Buy tokens
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/profile-favorited">
                                          <span>
                                            {" "}
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/images/landing-page/icons/favorites.svg"
                                              }
                                            />
                                          </span>
                                          Favorites
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/my-collection">
                                          <span>
                                            {" "}
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/images/landing-page/icons/menu.svg"
                                              }
                                            />
                                          </span>
                                          My Collection
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/social-media-import">
                                          <span>
                                            {" "}
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/images/landing-page/icons/social-media-import.svg"
                                              }
                                            />
                                          </span>
                                          Import Social Media
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/social-media-select-post">
                                          <span>
                                            {" "}
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/images/landing-page/icons/social-media-list.svg"
                                              }
                                            />
                                          </span>
                                          Social Media List
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/profile-setting">
                                          <span>
                                            {" "}
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/images/landing-page/icons/settings.svg"
                                              }
                                            />
                                          </span>
                                          Settings
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/logout");
                                          }}
                                          // onClick={() =>
                                          //   logout({
                                          //     logoutParams: {
                                          //       returnTo:
                                          //         window.location.origin + '/logout',
                                          //     },
                                          //   })
                                          // }
                                        >
                                          <span>
                                            {" "}
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/images/landing-page/icons/logout.svg"
                                              }
                                            />
                                          </span>
                                          Logout
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </Box>
                              ) : null}
                            </Box>
                          </ClickAwayListener>
                        </li>
                        <li>
                          <ClickAwayListener
                            mouseEvent="onMouseDown"
                            touchEvent="onTouchStart"
                            onClickAway={handleClickAway}
                          >
                            <Box sx={{ position: "relative" }}>
                              <button type="button" onClick={handleClick}>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/landing-page/icons/wallet-icon.svg"
                                  }
                                />
                              </button>
                              {open ? <AuthWallet /> : null}
                            </Box>
                          </ClickAwayListener>
                        </li>
                      </>
                    ) : (
                      <Link
                        // to="#"
                        className="default-btn"
                        // onClick={() => loginWithRedirect()}
                        to="/login"
                      >
                        LOG IN
                      </Link>
                    )}
                  </ul>
                  {/* )} */}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </div>
  );
};

const mapStateToPros = (state) => ({
  categories: state.nfts.categoriesList,
  profile: state.users.profile,
  loginInputData: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HeaderIndex);
